<script type="text/ecmascript-6">
    import MainLayout from '../layouts/Main.vue'
    import Tabs from '../components/Tabs.vue'
    import tabsMixin from '../mixins/tabs'
    import Listings from '../components/Listings.vue'
    import listingService from '../services/listing'
    import categoryService from '@/services/category.js'
    import brandService from '@/services/brand.js'
    import Paginate from 'vuejs-paginate'


    export default {
        components: { MainLayout, Listings, Paginate, Tabs},
        mixins: [tabsMixin],
        data() {
            
            return {

                listings: [],
                
                tabs: [
                    {
                        id: 'to_validate',
                        label: 'Annonces à valider',
                        isActive: true
                    },
                    {
                        id: 'accepted',
                        label: 'Annonces acceptées',
                        isActive: false
                    },
                    {
                        id: 'refused',
                        label: 'Annonces refusées',
                        isActive: false
                    },
                ],
                
                filterStatus:'to_validate',
                brands: [],
                categories: [],
                currentPage: 1,
                lastPage:null,
                countListing:null,
                isLoading: false

            }
        },
        methods: {
            async loadListings(reset) {

                this.isLoading = true 
                if (reset) {

                    this.currentPage = 1
                }

                let response = await listingService.list(this.currentPage, [this.filterStatus])
                this.listings = response.data.data 
                this.lastPage = response.data.last_page

                this.isLoading = false
            },
            async loadCategories() {

                let response = await categoryService.list(1)
                this.categories = response.data
            },
            async loadBrands() {

                let response = await brandService.list()
                this.brands = response.data
            },
            localToggleTabs(tabs, tabName) {

                this.toggleTabs(tabs, tabName)
                this.filterStatus = tabName 
                this.loadListings(true)
            },
            async loadCount() {

                let response = await listingService.count() 
                let data = response.data
                if (data.accepted == undefined) data.accepted = 0
                if (data.refused == undefined) data.refused = 0
                if (data.to_validate == undefined) data.to_validate = 0
                this.countListing = data
            },
            tabToggled({tabs, tabId}) {

                this.localToggleTabs(tabs, tabId)
            }
        },
        created() {

            if (this.$route.params.tab != null) this.localToggleTabs(this.tabs, this.$route.params.tab)

            this.$router.push({name: 'listings', hash: '#' + this.filterStatus})

            this.loadCategories()
            this.loadBrands()
            this.loadListings()
            this.loadCount()

            this.$tracking.pageView('Listings', {'Reseller ID': this.$store.state.resellerId})
        },
        computed: {
            listingsToValidate() {

                return this.listings.filter(listing => listing.status === 'to_validate')
            },
            listingsAccepted() {

                return this.listings.filter(listing => listing.status === 'accepted')
            },
            listingsRefused() {

                return this.listings.filter(listing => listing.status === 'refused')
            },
            countListingToValidate() {

                return this.countListing != null ? this.countListing.to_validate : 0
            },
            countListingAccepted() {

                return this.countListing != null ? this.countListing.accepted : 0
            },
            countListingRefused() {

                return this.countListing != null ? this.countListing.refused : 0
            },
        },
    }
</script>

<template>
    <main-layout>
        <h3>Annonces</h3>
        <tabs v-model="tabs" @tabToggled="tabToggled"/>
        <div class="row mx-n2 pt-2">
            <div class="col-md-3 col-sm-6 px-2 mb-4">
                <a href="#to_validate" v-on:click="localToggleTabs(tabs,'to_validate')" role="tab">
                    <div class="bg-white h-100 rounded-lg p-3 text-center" :class="{border: isTabActive(tabs, 'to_validate'), 'border-primary': isTabActive(tabs, 'to_validate')}">
                        <h3 class="font-size-sm text-muted">Annonces à valider</h3>
                        <p class="h2 mb-2 text-success">{{countListingToValidate}}</p>
                        <p class="font-size-xs" v-if="!isTabActive(tabs, 'to_validate')">Voir le détail</p>
                    </div>
                </a>
            </div>
            <div class="col-md-3 col-sm-6 px-2 mb-4">
                <a href="#accepted" v-on:click="localToggleTabs(tabs,'accepted')" role="tab">
                    <div class="bg-white h-100 rounded-lg p-3 text-center" :class="{border: isTabActive(tabs, 'accepted'), 'border-primary': isTabActive(tabs, 'accepted')}">
                        <h3 class="font-size-sm text-muted">Annonces acceptées</h3>
                        <p class="h2 mb-2 text-success">{{countListingAccepted}}</p>
                        <p class="font-size-xs" v-if="!isTabActive(tabs, 'accepted')">Voir le détail</p>
                    </div>
                </a>
            </div>
            <div class="col-md-3 col-sm-6 px-2 mb-4">
                <a href="#refused" v-on:click="localToggleTabs(tabs,'refused')" role="tab">
                    <div class="bg-white h-100 rounded-lg p-3 text-center" :class="{border: isTabActive(tabs, 'refused'), 'border-primary': isTabActive(tabs, 'refused')}">
                        <h3 class="font-size-sm text-muted">Annonces refusées</h3>
                        <p class="h2 mb-2 text-success">{{countListingRefused}}</p>
                        <p class="font-size-xs" v-if="!isTabActive(tabs, 'refused')">Voir le détail</p>
                    </div>
                </a>
            </div>
        </div>
        <div class="tab-content">
            <div class="tab-pane fade" :class="{show: isTabActive(tabs, 'to_validate'), active: isTabActive(tabs, 'to_validate')}" id="to_validate" role="tabpanel">
                <listings v-model="currentPage" :filter="filterStatus" v-if="listingsToValidate && this.brands.length > 0 && this.categories.length > 0" :currentTab="'listingsToValidate'" :listings="listingsToValidate" :brands="brands" :categories="categories" />
            </div>
            <div class="tab-pane fade" :class="{show: isTabActive(tabs, 'accepted'), active: isTabActive(tabs, 'accepted')}" id="accepted" role="tabpanel">
                <listings v-model="currentPage" :filter="filterStatus" v-if="listingsAccepted && this.brands.length > 0 && this.categories.length > 0" :currentTab="'listingsAccepted'" :listings="listingsAccepted"  :brands="brands" :categories="categories" />

            </div>
            <div class="tab-pane fade" :class="{show: isTabActive(tabs, 'refused'), active: isTabActive(tabs, 'refused')}" id="refused" role="tabpanel">
                <listings v-model="currentPage" :filter="filterStatus" v-if="listingsRefused && this.brands.length > 0 && this.categories.length > 0" :currentTab="'listingsRefused'" :listings="listingsRefused"  :brands="brands" :categories="categories" />
            </div>

        </div>
        <div class="d-flex">
            <nav aria-label="pagination" @click="loadListings(false)">
                <paginate v-if="lastPage != null"
                        v-model="currentPage"
                        :pageCount="lastPage"
                        :containerClass="'pagination'"
                        :pageClass="'page-item'"
                        :prevText="'<i class=\'czi-arrow-left\'></i> Précédent'"
                        :nextText="'Suivant <i class=\'czi-arrow-right\'></i>'"
                        :pageLinkClass="'page-link'"
                        :prevClass="'page-item'"
                        :nextClass="'page-item'"
                        :prevLinkClass="'page-link'"
                        :nextLinkClass="'page-link'"
                    />
            </nav>
            <span v-if="isLoading" class="spinner-border spinner-border-lg mr-2" role="status" aria-hidden="true"></span>
        </div>
    </main-layout>
</template>
