<script type="text/ecmascript-6">
    import { mapActions } from 'vuex'
    import AuthLayout from '../../layouts/Auth.vue'

    export default {
        components: {AuthLayout},
        data() {

            return {

                error: null,
                email: null,
                password: null
            }
        },
        methods: {
            ...mapActions({
                login: 'login'
            }),
            async submitSignIn() {

                try {

                    this.$tracking.track('Sign In Clicked')
    
                    await this.login({email: this.email, password: this.password})

                    this.$router.push({name: 'home'})
                }
                catch (error) {

                    this.error = 'Impossible de se connecter'

                    this.$tracking.track('Sign In Error')
                }
            },
        },
        created() {

            this.$tracking.pageView('Login')
        }
    }
</script>
<template>
    <auth-layout>
        <div v-if="error" class="alert alert-primary" role="alert">
            {{error}}
        </div>
        <form action="#" @submit.prevent="submitSignIn">
            <div class="form-group">
                <input required type="text" v-model="email" placeholder="Email" class="form-control w-100">
            </div>
            <div class="form-group">
                <input required type="password" v-model="password" placeholder="Mot de passe" class="form-control w-100">
            </div>
            <div class="form-group">
                <button class="btn btn-success w-100" type="submit">Se connecter</button>
            </div>
            <div class="form-group text-center">
                <router-link :to="{name: 'forgotPassword'}">J'ai oublié mon mot de passe</router-link>
            </div>
        </form>
    </auth-layout>
</template>
