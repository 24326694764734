<script type="text/ecmascript-6">

    import MainLayout from '../layouts/Main.vue'
    import Tabs from '../components/Tabs.vue'
    import tabsMixin from '../mixins/tabs'
    import specsServices from '../services/specs'

    export default {
        
        components: { MainLayout, Tabs },
        mixins: [tabsMixin],
        data() {

            return {

                 tabs: [
                    {
                        id: 'withOffers',
                        label: 'Offres actives',
                        isActive: false,
                        route: {name: 'offersSpecs', hash: '#withOffers'}
                    },
                    {
                        id: 'buyboxOk',
                        label: 'Offres Buybox OK',
                        isActive: false,
                        route: {name: 'offersSpecs', hash: '#buyboxOk'}
                    },
                    {
                        id: 'buyboxKo',
                        label: 'Offres Buybox KO',
                        isActive: false,
                        route: {name: 'offersSpecs', hash: '#buyboxKo'}
                    },
                    {
                        id: 'configuration',
                        label: 'Configurer',
                        isActive: true,
                        route: {name: 'specs'},
                        isButton: true
                    },
                    {
                        id: 'testSpecs',
                        label: 'Test de configuration',
                        isActive: false,
                        route: {name: 'testSpecs'},
                        isButton: false
                    }
                ],
                type: null,
                attributes: null,
                attribute: null,
                attributeValues: [],
                isSaving: false,
                attributesFullValues: []
            }
        },
        methods: {

            async loadAttributes() {

                this.attribute = null
                if (this.type == undefined || this.type == '') {

                    this.attributes = null
                    this.attributeValues = []
                }
                else {

                    let response = await specsServices.getFilters(this.type)
                    this.attributes = response.data

                    response = await specsServices.getValues(this.type)
                    this.attributeValues = response.data

                    this.loadAttributeValues()
                }
            },
            loadAttributeValues() {

                this.attributesFullValues = this.getAttributesFullValues()
            },
            toggleAttribute(attribute) {

                let index = this.getAttributeValueIndex(attribute.id)
                if (attribute.input == undefined) {

                    if (index == undefined) {

                        let input = this.isRatio() ? 100 : 0
                        this.currentAttributeValues.push({
                            value: attribute.value,
                            input: input,
                            id: attribute.id
                        })
                    }
                    else {

                        this.currentAttributeValues[index].input = this.isRatio() ? 100 : 0
                    }
                }
                else {

                    this.currentAttributeValues[index].input = null
                }

                this.attributesFullValues = this.getAttributesFullValues()
            },
            getAttributeValueIndex(id) {

                for (let i = 0; i < this.currentAttributeValues.length; i++) {

                    if (this.currentAttributeValues[i].id == id) {

                        return i;
                    }
                }

                return null
            },
            isRatio() {

                if (this.attribute == undefined) {

                    return false
                }

                return this.attribute.object.price_type == 'ratio'
            },
            async save() {

                this.isSaving = true
                await specsServices.update(this.type, this.attributeValues)
                await this.sleep(2)
                this.isSaving = false
            },
            getAttributesFullValues() {

                if (this.attributes == undefined || this.attribute == undefined) {

                    return []
                }
                
                let fullValues = []
                let values = this.attributes[this.attribute.name].values
                for (let i = 0; i < values.length; i++) {

                    let index = this.currentAttributeValues.findIndex(element => element.id == values[i].id)
                    if (index >= 0)
                        fullValues.push(this.currentAttributeValues[index])
                    else
                        fullValues.push({id: values[i].id, input: null, value: values[i].value ? values[i].value : values[i].id})
                }

                return fullValues
            },
            sleep(s) {
            
                return new Promise(resolve => setTimeout(resolve, s * 1000));
            },
            isEmpty(attributeValues) {

                if (attributeValues.length == 0) {

                    return true
                }

                return attributeValues.filter(element => element.input != undefined).length == 0
            }
        },
        computed: {

            attributeUnit() {

                return this.attributes && this.attribute && this.attributes[this.attribute.name] ? this.attributes[this.attribute.name]['unit'] : null
            },
            currentAttributeValues() {
                
                return this.attributeValues && this.attribute ? this.attributeValues[this.attribute.name] : null
            },
            canSave() {

                return this.type && this.attribute
            },
            noValueAttributes() {

                if (this.attributeValues && this.attributes) {

                    return Object.keys(this.attributes).filter(element => {

                        if (this.attributeValues[element] == undefined || this.isEmpty(this.attributeValues[element])) {

                            return element
                        }

                        return null
                    });
                } 

                return []
            },
            nbrValues() {

                return this.attributesFullValues ? this.attributesFullValues.length : 0
            },
            nbrCompletedValues() {

                if (this.attributesFullValues == undefined) return 0

                return this.attributesFullValues.filter(element => element.input != undefined).length
            },
            isAdmin() {

                return this.$store.getters.isAdmin
            }
        },
        created() {

            this.$tracking.pageView('Specs', {'Reseller ID': this.$store.state.resellerId})
        }
    }
</script>
<template>
    <main-layout>
        <h3>Offres par spécifications</h3>
        <tabs v-model="tabs" />
        <form v-on:submit.prevent>
            <div class="row">
                <div class="col-md-8">
                    <div class="form-group row align-items-center">
                        <label for="type-input" class="col-md-3 col-form-label font-weight-medium">Type de produit</label>
                        <div class="col-md-9">
                            <select class="form-control custom-select" id="type-input" v-model="type" @change="loadAttributes">
                                <option :value="null">Choisir...</option>
                                <option value="laptop">PC portables</option>
                                <option value="desktop">PC fixes</option>
                                <option value="monitor">Ecrans d'ordinateur</option>   
                            </select>
                        </div>
                    </div>
                    <div v-if="attributes" class="form-group row align-items-center">
                        <label for="attribute-input" class="col-md-3 col-form-label font-weight-medium">Attribut</label>
                        <div class="col-md-9">
                            <select class="form-control custom-select" id="attribute-input" v-model="attribute" @change="loadAttributeValues">
                                <option :value="null">Choisir...</option>
                                <option v-for="(object, name) in attributes" :key="name" :value="{name: name, object: object}">{{$t('ATTRIBUTE_NAME.'+name)}}</option>
                            </select>
                        </div>
                    </div>
                    <div class="row">
                        <div class="text-right col-md-9 offset-md-3">
                            <span v-if="nbrValues > 0"><strong>Total : {{nbrCompletedValues}} / {{nbrValues}}</strong></span>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-9 offset-md-3">
                            <table class="table table-bordered">
                                <tr v-for="attributesFullValue in attributesFullValues" :key="attributesFullValue.id">
                                    <td width="75%"><strong>{{attributesFullValue.value}} {{attributeUnit}}</strong></td>
                                    <td class="text-right text-nowrap">
                                        <input :disabled="!isAdmin" type="checkbox" id="checkbox" class="mr-2" @click="toggleAttribute(attributesFullValue)" :checked="attributesFullValue.input != undefined">
                                        <input :disabled="attributesFullValue.input == undefined || !isAdmin" :min="isRatio() ? 0 : -100" :max="isRatio() ? 100 : 1000" class="text-right" style="width: 7em" type="number" v-model="attributesFullValue.input" /> {{isRatio() ? '%' : '€'}}
                                    </td>
                                </tr>
                            </table>
                        </div>
                    </div>
                    <div v-if="canSave" class="row text-right">
                        <div class="col-md-9 offset-md-3">
                            <button v-if="isAdmin" class="btn btn-success" :disabled="isSaving || !canSave" @click="save">
                                <span v-if="isSaving" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                                Sauvegarder
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <div class="form-group row">
                        <button v-if="isAdmin" class="btn btn-success" :disabled="isSaving || !canSave" @click="save">
                            <span v-if="isSaving" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                            Sauvegarder
                        </button>
                    </div>
                    <div v-if="this.attribute" class="row alert alert-info">
                        <p>Lorsqu'une case est décochée, aucune offre n'est générée pour des produits avec cette valeur d'attribut.</p>
                    </div>
                    <div class="row alert alert-warning" v-if="noValueAttributes.length > 0" role="alert">
                        <p>Aucun prix n'a été mentionné dans ces attributs, aucune offre ne pourra être générée.</p>
                        <ul>
                            <li v-for="noValueAttribute in noValueAttributes" :key="noValueAttribute">{{$t('ATTRIBUTE_NAME.'+noValueAttribute)}}</li>
                        </ul>
                    </div>
                </div>
            </div>
        </form>
    </main-layout>
</template>
