<script type="text/ecmascript-6">
    import offerService from '../services/offer'
    import MainLayout from '../layouts/Main.vue'
    import Tabs from '../components/Tabs.vue'
    import tabsMixin from '../mixins/tabs'
    import MultiSelectFilters from '../components/MultiSelectFilters.vue'
    import Offers from '../components/OffersSpecs.vue'
    import OfferDetail from '../components/OfferDetail.vue'

    export default {
        components: { MainLayout, Tabs, MultiSelectFilters, Offers, OfferDetail },
        mixins: [tabsMixin],
        data() {
            
            return {

                offers: {},
                filtersValues: {},
                filters: {
                    search: '',
                    sku: '',
                    condition: [],
                    isWorking: [],
                    isComplete: [],
                    isBestOffer: null,
                    brand: [],
                    attributes: {
                        gpu: [],
                        processorGeneration: [],
                        hardDrive: [],
                        ram: [],
                        diagonal: [],
                        displayType: [],
                        processorFamily: []
                    },
                    type: 'specs',
                    categoryId: ''
                },
                newFilters: {
                    search: '',
                    sku: '',
                    condition: [],
                    isWorking: [],
                    isComplete: [],
                    isBestOffer: null,
                    brand: [],
                    attributes: {
                        gpu: [],
                        processorGeneration: [],
                        hardDrive: [],
                        ram: [],
                        diagonal: [],
                        displayType: [],
                        processorFamily: []
                    },
                    type: 'specs',
                    categoryId: ''
                },
                currentPage: 1,
                tabs: [
                    {
                        id: 'withOffers',
                        label: 'Offres actives',
                        isActive: true,
                        isButton: false
                    },
                    {
                        id: 'buyboxOk',
                        label: 'Offres Buybox OK',
                        isActive: false,
                        isButton: false
                    },
                    {
                        id: 'buyboxKo',
                        label: 'Offres Buybox KO',
                        isActive: false,
                        isButton: false
                    },
                    {
                        id: 'configuration',
                        label: 'Configurer',
                        isActive: false,
                        route: {name: 'specs'},
                        isButton: true
                    },
                    {
                        id: 'testSpecs',
                        label: 'Test de configuration',
                        isActive: false,
                        route: {name: 'testSpecs'},
                        isButton: false
                    }
                ],
                isApplyFiltersLoading: false,
                isResetFiltersLoading: false,
                buyBoxCountOK: 0,
                buyBoxCountKO: 0,
                offerDetail: null,
                categories: []
            }
        },
        methods: {

            async loadOffers(isReseted) {

                if (isReseted == true)
                    this.isResetFiltersLoading = true
                else
                    this.isApplyFiltersLoading = true

                let response = await offerService.list(this.currentPage, this.filters)
                this.offers = response.data

                this.isApplyFiltersLoading = false
                this.isResetFiltersLoading = false
            },
            async loadFilters() {

                let response = await offerService.getFilters(true)
                this.filtersValues = response.data
            },
            async loadProductCategoryFilters() {

                let response = await offerService.getProductCategoryFilters()
                this.categories = response.data
            },
            loadCounts() {

                offerService.countBuyBox(this.filters, 1)
                    .then((response) => {

                        this.buyBoxCountOK = response.data.total
                    })

                offerService.countBuyBox(this.filters, 0)
                    .then((response) => {

                        this.buyBoxCountKO = response.data.total
                    })
            },
            applyFilters(isReseted) {
    
                Object.assign(this.filters, this.newFilters)
                if (this.currentPage == 1) {

                    this.loadOffers(isReseted)
                    this.loadCounts()
                }
                else {

                    this.loadCounts()
                    this.currentPage = 1
                } 
            },
            resetFilters() {

                let isBestOffer = null
                if (this.isTabActive(this.tabs, 'buyboxOk')) {

                    isBestOffer = 1
                }
                else if (this.isTabActive(this.tabs, 'buyboxKo')) {

                    isBestOffer = 0
                }

                this.newFilters = {
                    search: '',
                    sku: '',
                    condition: [],
                    isWorking: [],
                    isComplete: [],
                    brand: [],
                    attributes: {
                        gpu: [],
                        processorGeneration: [],
                        hardDrive: [],
                        ram: [],
                        diagonal: [],
                        displayType: [],
                        processorFamily: []
                    },
                    isBestOffer: isBestOffer,
                    categoryId: ''
                }
                this.applyFilters(true)
            },
            toggleTabAndLoad(tabId) {

                this.toggleTabs(this.tabs, tabId)
                this.loadOffersByType()
            },
            loadOffersByType() {

                if (this.isTabActive(this.tabs, 'withOffers')) {

                    this.newFilters.isBestOffer = null
                }
                else if (this.isTabActive(this.tabs, 'buyboxOk')) {

                    this.newFilters.isBestOffer = 1
                }
                else if (this.isTabActive(this.tabs, 'buyboxKo')) {

                    this.newFilters.isBestOffer = 0
                }

                this.applyFilters(false)
            },
            async openOfferDetail(offer) {

                let response = await offerService.get(offer.id)
                this.offerDetail = response.data 
            },
        },
        created() {

            this.loadTab(this.tabs)
            this.loadOffers()
            this.loadFilters()
            this.loadCounts()
            this.loadProductCategoryFilters()

            this.$tracking.pageView('OffersSpecs', {'Reseller ID': this.$store.state.resellerId})
        },
        computed: {

            areOffersLoaded() {

                return Object.keys(this.offers).length > 0
            },
            conditionFilters() {

                return 'conditions' in this.filtersValues ? this.filtersValues['conditions'] : {}
            },
            isCompleteFilters() {

                return 'isComplete' in this.filtersValues ? this.filtersValues['isComplete'] : {}
            },
            isWorkingFilters() {

                return 'isWorking' in this.filtersValues ? this.filtersValues['isWorking'] : {}
            },
            attrGPUFilters() {

                return 'gpu' in this.filtersValues ? this.filtersValues['gpu'] : {}
            },
            attrBrandFilters() {

                return 'brand' in this.filtersValues ? this.filtersValues['brand'] : {}
            },
            attrProcessorGenerationFilters() {

                return 'processor_generation' in this.filtersValues ? this.filtersValues['processor_generation'] : {}
            },
            attrHardDriveFilters() {

                return 'hard_drive' in this.filtersValues ? this.filtersValues['hard_drive'] : {}
            },
            attrRAMFilters() {

                return 'ram' in this.filtersValues ? this.filtersValues['ram'] : {}
            },
            attrDiagonalFilters() {

                return 'diagonal' in this.filtersValues ? this.filtersValues['diagonal'] : {}
            },
            attrDisplayTypeFilters() {

                return 'display_type' in this.filtersValues ? this.filtersValues['display_type'] : {}
            },
            attrProcessorFamilyFilters() {

                return 'processor_family' in this.filtersValues ? this.filtersValues['processor_family'] : {}
            },
            offersData() {

                return this.offers.data
            },
            allCount() {

                return this.buyBoxCountKO + this.buyBoxCountOK
            }
        },
        watch: {

            currentPage() {

                Object.assign(this.newFilters, this.filters)
                this.loadOffers()
            },
            '$route.hash': function() {

                this.loadTab(this.tabs)
            }
        }
    }
</script>
<template>
    <main-layout>
        <h3>Offres par spécifications</h3>
        <tabs v-model="tabs" @tabToggled="loadOffersByType()"/>
        <div class="row mx-n2 pt-2">
            <div class="col-md-4 col-sm-6 px-2 mb-4">
                <a href="#withOffers" v-on:click="toggleTabAndLoad('withOffers')" role="tab">
                    <div class="bg-white h-100 rounded-lg p-3 text-center" :class="{border: isTabActive(tabs, 'withOffers'), 'border-primary': isTabActive(tabs, 'withOffers')}">
                        <h3 class="font-size-sm text-muted">Offres d'achat actives</h3>
                        <p class="h2 mb-2 text-success">{{ allCount }}</p>
                        <span v-if="isResetFiltersLoading || isApplyFiltersLoading" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                    </div>
                </a>
            </div>
            <div class="col-md-4 col-sm-6 px-2 mb-4">
                <a href="#buyboxOk" v-on:click="toggleTabAndLoad('buyboxOk')" role="tab">
                    <div class="bg-white h-100 rounded-lg p-3 text-center" :class="{border: isTabActive(tabs, 'buyboxOk'), 'border-primary': isTabActive(tabs, 'buyboxOk')}">
                        <h3 class="font-size-sm text-muted">Buybox OK</h3>
                        <p class="h2 mb-2 text-success">{{ buyBoxCountOK }}</p>
                        <span v-if="isResetFiltersLoading || isApplyFiltersLoading" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                    </div>
                </a>
            </div>
            <div class="col-md-4 col-sm-6 px-2 mb-4">
                <a href="#buyboxKo" v-on:click="toggleTabAndLoad('buyboxKo')" role="tab">
                    <div class="bg-white h-100 rounded-lg p-3 text-center" :class="{border: isTabActive(tabs, 'buyboxKo'), 'border-primary': isTabActive(tabs, 'buyboxKo')}">
                        <h3 class="font-size-sm text-muted">Buybox KO</h3>
                        <p class="h2 mb-2 text-danger">{{ buyBoxCountKO }}</p>
                        <span v-if="isResetFiltersLoading || isApplyFiltersLoading" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                    </div>
                </a>
            </div>
        </div>
        <form v-on:submit.prevent>
            <div class="row">
                <div class="col-8">
                    <div class="row">
                        <div class="col-6 form-group">
                            <input class="cz-filter-search form-control" v-model="newFilters.search" type="text" placeholder="Libéllé">
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-4 form-group">
                            <multi-select-filters v-model="newFilters.condition" :items="conditionFilters" :label="'Etat'" :id="'conditions'" />
                        </div>
                        <div class="col-4 form-group">
                            <multi-select-filters v-model="newFilters.isWorking" :items="isWorkingFilters" :label="'Fonctionnel ?'" :id="'isWorking'" />
                        </div>
                        <div class="col-4 form-group">
                            <multi-select-filters v-model="newFilters.isComplete" :items="isCompleteFilters" :label="'Complet ?'" :id="'isComplete'" />
                        </div>
                        <div class="col-4 form-group">
                            <multi-select-filters v-model="newFilters.attributes.diagonal" :items="attrDiagonalFilters" :label="'Diagonale'" :id="'diagonal'" />
                        </div>
                         <div class="col-4 form-group">
                            <multi-select-filters v-model="newFilters.attributes.displayType" :items="attrDisplayTypeFilters" :label="'Ecran'" :id="'displayType'" />
                        </div>
                        <div class="col-4 form-group">
                            <multi-select-filters v-model="newFilters.attributes.processorFamily" :items="attrProcessorFamilyFilters" :label="'Famille de processeur'" :id="'processorFamily'" />
                        </div>
                        <div class="col-4 form-group">
                            <multi-select-filters v-model="newFilters.attributes.processorGeneration" :items="attrProcessorGenerationFilters" :label="'Génération de processeur'" :id="'processorGeneration'" />
                        </div>
                        <div class="col-4 form-group">
                            <multi-select-filters v-model="newFilters.attributes.ram" :items="attrRAMFilters" :label="'RAM'" :id="'RAM'" />
                        </div>
                        <div class="col-4 form-group">
                            <multi-select-filters v-model="newFilters.attributes.hardDrive" :items="attrHardDriveFilters" :label="'Type de disque dur'" :id="'hardDrive'" />
                        </div>
                        <div class="col-4 form-group">
                            <multi-select-filters v-model="newFilters.attributes.gpu" :items="attrGPUFilters" :label="'GPU'" :id="'GPU'" />
                        </div>
                        <div class="col-4 form-group">
                            <multi-select-filters v-model="newFilters.brand" :items="attrBrandFilters" :label="'Marque'" :id="'brand'" />
                        </div>
                        <div class="col-4 form-group">
                            <select v-model="newFilters.categoryId" class="cz-filter-search form-control">
                                <option value="" disabled selected>Catégorie</option>
                                <option v-bind:key="category.id" :value="category.id" v-for="category in categories"  placeholder="Catégorie">
                                    {{ category.name }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="col-4">
                    <div class="row">
                        <div class="col form-group">
                            <button class="btn btn-success w-100" type="submit" v-on:click="applyFilters" :disabled="isApplyFiltersLoading"><span v-if="isApplyFiltersLoading" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>Appliquer les filtres</button>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col form-group">
                            <button class="btn btn-primary w-100" type="submit" v-on:click="resetFilters" :disabled="isResetFiltersLoading"><span v-if="isResetFiltersLoading" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>Effacer les filtres</button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
        <offers :offers="offers" v-model="currentPage" :canEditPrices="false" @offerClicked="openOfferDetail($event)" />
        <offer-detail v-model="offerDetail" @close="offerDetail = null"/>
    </main-layout>
</template>
