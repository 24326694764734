<script type="text/ecmascript-6">

import MainLayout from '@/layouts/Main.vue'
import offerService from '@/services/offer'
import Offers from '@/components/Offers.vue'
import StandardModal from '@/components/StandardModal'
import MultiSelectFilters from '@/components/MultiSelectFilters.vue'
import OfferTemplateProducts from '@/components/OfferTemplateProducts.vue'

export default {
    components: { MainLayout, Offers, StandardModal, MultiSelectFilters, OfferTemplateProducts},
    mixins: [],
    data() {
        
        return {
            
            offers:{},
            modifiedOffers:{
                data:[]
            },
            offerTemplate:{},
            offerTemplateTab: true,
            currentPage: 1,
            filters: {
                search: '',
                sku: '',
                displayedBrand: '',
                condition: '',
                isWorking: '',
                isComplete: '',
                isBestOffer: null,
                offerTemplateId: parseInt(this.$route.params.id),
            },
            frontfilters: {

                condition: [],
                isWorking: '',
                isComplete: '',
                withBuybox: ''
            },
            alignedOfferIndex: null,
            offerLabel: '',
            offerBrand: '',
            isDataLoading: false,
            categoryHasComplete: null,
            categoryHasCondition: null,
            buyBoxToAlign: null,
            isOfferPriceUpdateLoading : false,
            error: null,
            displayProducts: false,
            openUpdateMultiplePrices:false,
            offersToUpdate: [],
            rebootComponentKey:0
        }  
    },
    methods: {

        async loadOffers() {

            this.offers = {}
            this.isDataLoading = true
            this.isOfferPriceUpdateLoading = true
            let response = await offerService.list(this.currentPage, this.filters, false)

            this.offers = response.data

            response = await offerService.getOfferTemplate(this.filters.offerTemplateId, true)
            this.offerTemplate = response.data

            if (this.offers.length > 0) {

                this.offerLabel = this.offers[0].reseller_product_label
                this.offerBrand = this.offers[0].displayed_brand
            } 
            else {

                this.offerLabel = this.offerTemplate.label
                this.offerBrand = this.offerTemplate.displayed_brand
            }

            this.fillAbsentOffers(this.offerTemplate.category.has_complete, this.offerTemplate.category.has_condition)

            this.offerCategory = this.offerTemplate.category
            this.isDataLoading = false
            this.isOfferPriceUpdateLoading = false
        },
        async createPrice (index) {

            this.isOfferPriceUpdateLoading = true
            this.error = null
            var formData= { 

                brand: this.offerBrand,
                label: this.offerLabel,
                categoryId: this.offerTemplate.category.id,
                conditionId:  this.offersData[index].product_condition_id,
                isWorking: this.offersData[index].is_working,
                isComplete: this.offersData[index].is_complete,
                totalPrice: this.offersData[index].total_price,
                offerTemplateId: this.offerTemplate.id
            }

            try {

                await offerService.createPrice(formData)
            }
            catch (error) {

                this.modifiedOffers.data[index].isError = 'PRICE_KO'
            }
            finally {

                this.loadOffers() 
                this.isOfferPriceUpdateLoading = false
                this.offersToUpdate = []
                this.rebootComponentKey += 1
            }
        },
        async alignPrice(index) {
            
            this.modifiedOffers.data[index].total_price = this.buyBoxToAlign

            if (this.modifiedOffers.data[index].id != null) {

                await this.updatePrice(index)
                this.alignedOfferIndex = null
            }
            else {

                await this.createPrice(index)
                this.alignedOfferIndex = null
            }

        },
        async updateMultiplePrices() {

            this.isOfferPriceUpdateLoading = true

            try {

                await offerService.updatePrice(this.offersToUpdate)

                this.$tracking.track('Prices Updated', {'Reseller ID': this.$store.state.resellerId, 'Offer Template ID': this.$route.params.id, 'Number Of Offers Updated': this.offersToUpdate.length})
            }
            catch (error) {

                if (error.response && error.response.status == 422) {

                    this.error = 'Un prix est vide ou au mauvais format.'
                }

                this.$tracking.track('Update Prices Error', {'Reseller ID': this.$store.state.resellerId, 'Offer Template ID': this.$route.params.id})
            }
            finally {

                this.loadOffers() 
                this.isOfferPriceUpdateLoading = false
                this.openUpdateMultiplePrices = false
                this.offersToUpdate = []
                this.rebootComponentKey += 1
            }
        },
        async updatePrice(index) {

            this.isOfferPriceUpdateLoading = true
            let offer = this.modifiedOffers.data[index]

            let offerToUpdate = []

            offerToUpdate.push(offer)

            try {

                await offerService.updatePrice(offerToUpdate)

                this.$tracking.track('Prices Updated', {'Reseller ID': this.$store.state.resellerId, 'Offer Template ID': this.$route.params.id, 'Number Of Offers Updated': 1})
            }
            catch (error) {

                if (error.response && error.response.status == 422) {

                    this.error = 'Un prix est vide ou au mauvais format.'
                }

                this.$tracking.track('Update Prices Error', {'Reseller ID': this.$store.state.resellerId, 'Offer Template ID': this.$route.params.id})
            }
            finally {

                this.loadOffers() 
                this.isOfferPriceUpdateLoading = false
                this.rebootComponentKey += 1
            }
        },
        fillAbsentOffers(categoryHasComplete, categoryHasCondition) {

            this.modifiedOffers.data = []

            var maxComplete = 0
            var maxCondition = 1
            var defaultCompleteId = 3
            var defaultConditionId = 10

            if (categoryHasComplete) { 

                maxComplete = 1
            }

            if (categoryHasCondition) { 

                maxCondition = 5
            }

            for (let completeId = 0; completeId <= maxComplete; completeId++) {

                for (let conditionId = 1; conditionId <= maxCondition; conditionId++) {

                    for (let workingId = 0; workingId <= 1; workingId++) {
                        
                        var offerPresent = this.offers.find(offer => ((offer.is_complete === completeId || offer.is_complete === defaultCompleteId) && (offer.product_condition_id === conditionId || offer.product_condition_id === defaultConditionId ) && offer.is_working === workingId))

                        if (offerPresent) {
                            
                            this.modifiedOffers.data.push(offerPresent)
                        }
                        else {

                            var conditionName 

                            switch(conditionId) {

                                case 1: 
                                    conditionName = "COMME NEUF"
                                    break;
                                case 2: 
                                    conditionName = "TRES BON ETAT"
                                    break;
                                case 3: 
                                    conditionName = "BON ETAT"
                                    break;
                                case 4: 
                                    conditionName = "CORRECT"
                                    break;
                                case 5: 
                                    conditionName = "ENDOMMAGE"
                                    break;
                                
                                default:
                                    conditionName = "N/A"
                            }

                            if (!categoryHasComplete) { 

                                completeId = defaultCompleteId
                            }

                            if (!categoryHasCondition) { 

                                conditionId = defaultConditionId
                            }

                            this.modifiedOffers.data.push({

                                'is_complete':completeId,
                                'product_condition_id':conditionId,
                                'is_working': workingId,
                                'reseller_product_sku': '',
                                'reseller_product_label': this.offerLabel,
                                'condition_name' : conditionName,
                                "price": null,
                                "total_price": null,
                                "commission_excl_tax_price": null,
                                "logistic_excl_tax_price": null,
                                "displayed_brand":  this.offerBrand,
                                "template_id" : this.filters.offerTemplateId,
                                'category_id': this.offerTemplate.category,
                                'be_best_offer_total_price':"0.00"
                            })
                        }
                    }
                }
            }

            
            this.modifiedOffers.data.sort((a, b) => parseFloat(b.is_complete) - parseFloat(a.is_complete))
            this.modifiedOffers.data.sort((a, b) => parseFloat(a.product_condition_id) - parseFloat(b.product_condition_id))
            this.modifiedOffers.data.sort((a, b) => parseFloat(b.is_working) - parseFloat(a.is_working))
        }
    },
    created() {

        this.loadOffers()
        
        this.$tracking.pageView('OfferTemplateDetail', {'Reseller ID': this.$store.state.resellerId, 'Offer Template ID': this.$route.params.id})
    }, 
    computed: {

        conditionFilters() {

            return [
                {id: 1, name: 'COMME NEUF'},
                {id: 2, name: 'TRES BON ETAT'},
                {id: 3, name: 'BON ETAT'},
                {id: 4, name: 'CORRECT'},
                {id: 5, name: 'ENDOMMAGE'}
            ] 
        },
        offersData() {

            return this.modifiedOffers.data != null ? this.modifiedOffers.data : this.offers
        },
        filteredModifiedOffersData() {

            if (this.modifiedOffers.data == null) return null

            let data = this.modifiedOffers.data

            if (this.frontfilters.isWorking != '') 
                data = data.filter(element => element.is_working == this.frontfilters.isWorking)

            if (this.frontfilters.isComplete != '') 
                data = data.filter(element => element.is_complete == this.frontfilters.isComplete)

            if (this.frontfilters.condition.length > 0) 
                data = data.filter(element => this.frontfilters.condition.includes(element.product_condition_id))

            if (this.frontfilters.withBuybox == '1') 
                data = data.filter(element => Number(element.nbr_best_offers_ok) > 0 && Number(element.nbr_best_offers_ok) == element.nbr_product_offers)

            if (this.frontfilters.withBuybox == '0')
                data = data.filter(element => element.nbr_best_offers_ok == undefined || element.nbr_best_offers_ok == 0)

            return data
        }
    }
}
</script>

<template>
    <main-layout>
        <ul class="nav nav-tabs">
            <li class="nav-item">
                <router-link  :to="{name:'offerTemplates'}" :class="{active : offerTemplateTab}" class="nav-link" role="tab">
                    Toutes les offres
                </router-link>
            </li>
        </ul>
        <div class="d-flex flex-row justify-content-between align-items-center  m-3">
            <span v-if="isDataLoading" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
            <p v-else class="ml-2 mb-0">
                <strong> {{ offerBrand }} - </strong>
                <strong>{{ offerTemplate.label }}</strong>
                <i title="Liste des produits" role="button" class="ml-2 czi-eye" @click="displayProducts = true"></i>
            </p>
        </div>
        <div v-if="false" class="row">
            <div class="col-3 form-group">
                <select class="cz-filter-search form-control" v-model="frontfilters.isWorking">
                    <option value="">Fonctionnel & non fonctionnel</option>
                    <option value="1">Fonctionnel</option>
                    <option value="0">Non fonctionnel</option>
                </select>
            </div>
            <div class="col-3 form-group">
                <select class="cz-filter-search form-control" v-model="frontfilters.isComplete">
                    <option value="">Complet & incomplet</option>
                    <option value="1">Complet</option>
                    <option value="0">Incomplet</option>
                </select>
            </div>
            <div class="col-3 form-group">
                <multi-select-filters v-model="frontfilters.condition" :items="conditionFilters" :label="'Etat'" :id="'conditions'" />
            </div>
            <div class="col-3 form-group">
                <select class="cz-filter-search form-control" v-model="frontfilters.withBuybox">
                    <option value="">Avec buybox & sans buybox</option>
                    <option value="0">Sans buybox</option>
                    <option value="1">Avec buybox</option>
                </select>
            </div>
        </div>
        <offers 
        :offers="filteredModifiedOffersData" 
        :isOfferPriceUpdateLoading="isOfferPriceUpdateLoading" 
        @createPrice="createPrice($event)" 
        @updatePrice="updatePrice($event)" 
        @alignPriceClicked="alignedOfferIndex = $event" 
        @buyBoxClicked="buyBoxToAlign = $event" 
        @openUpdateMultiplePrices="openUpdateMultiplePrices = $event"
        @offersToUpdate="offersToUpdate = $event"
        :key="rebootComponentKey"
        :displayEyeRow="false"
        />
        <offer-template-products v-if="displayProducts" :products="offerTemplate.products" @close="displayProducts = false"/>
        <standard-modal v-if="alignedOfferIndex != undefined" :closeLabel="'Annuler'" :title="'Aligner le prix de l\'offre'" @close="alignedOfferIndex = null" :isOfferPriceUpdateLoading="isOfferPriceUpdateLoading">
            <template v-slot:body>
                <p>Confirmez l'alignement du prix de l'offre à {{$n(buyBoxToAlign, 'currency')}} ?</p>
            </template>
            <template v-slot:footer>
                <button :disabled=isOfferPriceUpdateLoading role="button" class="btn btn-danger" @click="alignPrice(alignedOfferIndex)">
                    Valider
                    <span v-if="isOfferPriceUpdateLoading" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                </button>
            </template>
        </standard-modal>
        <standard-modal v-if="openUpdateMultiplePrices" :closeLabel="'Annuler'" :title="'Mettre à jour plusieurs prix'" @close="openUpdateMultiplePrices = false">
            <template v-slot:body >
                <p>Confirmez la mise à jour de toutes ces références ?</p>
                <table class="table table-hover font-size-sm">
                        <thead>
                            <th class="font-size-xs">Fonctionnel</th>
                            <th class="font-size-xs">Etat</th>
                            <th class="font-size-xs">Complet</th>
                            <th>Nouveau Prix d'achat</th>
                        </thead>
                        <tbody>
                            <tr  class="bg-white" v-for="(offer, index) in offersToUpdate" :key="index">
                                <td class="align-middle text-center">
                                    <i v-if="offer.is_working" class="text-success czi-check" />
                                    <i v-if="!offer.is_working" class="text-danger czi-close" />
                                </td>
                                <td class="align-middle font-size-xs">{{ offer.condition_name }}</td>
                                <td class="align-middle text-center">
                                    <i v-if="offer.is_complete === 1" class="czi-check" />
                                    <span class="font-size-xs" v-if="offer.is_complete === 3">N/A</span>
                                </td>
                               
                                <td class="align-middle">
                                    <span v-if="offer.total_price">{{$n(offer.total_price, 'currency') }}</span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
            </template>
            <template v-slot:footer>
                <button :disabled=isOfferPriceUpdateLoading role="button" class="btn btn-success" @click="updateMultiplePrices">
                    Valider
                    <span v-if="isOfferPriceUpdateLoading" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                </button>
            </template>
        </standard-modal>
        <standard-modal v-if="error" :closeLabel="'Fermer'" :title="'Erreur'" @close="error = null">
            <template v-slot:body >
                {{ error }}
            </template>
        </standard-modal>
    </main-layout>
</template>