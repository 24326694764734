import Vue from 'vue'
import Vuex from 'vuex'
import authService from '../services/auth.js'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        user: null,
        testSpecsOffers: {},
        resellerId: null,
        reseller: null
    },
    getters: {
        user: state => { return state.user },
        resellerId: state => { return state.resellerId },
        reseller: state => { return state.reseller },
        isAdmin: state => { return state.user != undefined && state.user.roles.find(element => element.app == 'Reseller' && element.role == 'Admin') != undefined },
        resellers: state => { return state.user != undefined ? state.user.roles.filter(element => element.app == 'Reseller').map(element => element.reseller) : null}
    },
    mutations: {
        setUser (state, user) {
            
            if (user != null) {

                let resellerId = sessionStorage.getItem('resellerId')
                let applicationRole = undefined
                
                if (resellerId) {

                    applicationRole = user.roles.find(role => role.app == 'Reseller' && role.reseller_id == resellerId)
                }
                
                if (applicationRole == undefined) {

                    applicationRole = user.roles.find(role => role.app == 'Reseller')
                }
                
                if (applicationRole) {

                    state.user = user
                    this.commit('setReseller', applicationRole.reseller)
                }
            }
            else {

                state.user = user
            }
        },
        setReseller(state, reseller) {

            state.resellerId = reseller.id
            state.reseller = reseller
            sessionStorage.setItem('resellerId', reseller.id)
        },
        addTestSpecsOffers(state, {type, copiedOffer}) {

            state.testSpecsOffers[type].push(copiedOffer)
            state.testSpecsOffers = { ...state.testSpecsOffers }
        },
        initTestSpecsOffers(state, types) {

            for (let type of types) {

                state.testSpecsOffers[type] = []
            }
        },
        switchResellerId(state, resellerId) {

            if (state.user) {
                
                let resellerRole = state.user.roles.find(element => element.app === 'Reseller' && element.reseller_id == resellerId);
                if (resellerRole) {

                    this.commit('setReseller', resellerRole.reseller)

                    this._vm.$tracking.track('Reseller Switched')
                }
            }
        }
    },
    actions: {
        async getCSRF() {

            await authService.getCSRF()
        },
        async login({ dispatch }, credentials) {

            let response = await authService.login(credentials.email, credentials.password)
            if (response.data != '1' && response.data != '0') {

                sessionStorage.setItem('user-token', response.data);
            }

            return dispatch('me')
        },
        async logout ({ dispatch }) {

            sessionStorage.removeItem('user-token');
            await authService.logout()
    
            return dispatch('me')
        },
        me ({ commit }) {
            return authService.getCurrentUser()
                .then((response) => {

                    commit('setUser', response.data)

                    this._vm.$tracking.setUserId(response.data.id)

                    return response.data
                    
                }).catch(() => {

                    commit('setUser', null)

                    return null
            })
        }
    }
})
