<script type="text/ecmascript-6">

    import MainLayout from '../layouts/Main.vue'
    import Tabs from '../components/Tabs.vue'
    import tabsMixin from '../mixins/tabs'
    import specsServices from '../services/specs'
    
    export default {
        
        components: { MainLayout, Tabs },
        mixins: [tabsMixin],
        data() {

            return {

                 tabs: [
                    {
                        id: 'withOffers',
                        label: 'Offres actives',
                        isActive: false,
                        route: {name: 'offersSpecs', hash: '#withOffers'}
                    },
                    {
                        id: 'buyboxOk',
                        label: 'Offres Buybox OK',
                        isActive: false,
                        route: {name: 'offersSpecs', hash: '#buyboxOk'}
                    },
                    {
                        id: 'buyboxKo',
                        label: 'Offres Buybox KO',
                        isActive: false,
                        route: {name: 'offersSpecs', hash: '#buyboxKo'}
                    },
                    {
                        id: 'configuration',
                        label: 'Configurer',
                        isActive: false,
                        route: {name: 'specs'},
                        isButton: true
                    },
                    {
                        id: 'testSpecs',
                        label: 'Test de configuration',
                        isActive: true,
                        route: {name: 'testSpecs'},
                        isButton: false
                    }
                ],
                type: null,
                filters: null,
                filtersSelected: null,
                specsValues: null,
                error: ''
            }
        },
        methods: {

            async loadAttributes() {

                if (this.type == undefined || this.type == '') {

                    this.filters = null
                    this.specsValues = null
                    this.filtersSelected = null
                }
                else {

                    let response = await specsServices.getFilters(this.type)
                    this.filters = response.data

                    this.filtersSelected = this.getFiltersSelected()

                    response = await specsServices.getValues(this.type)
                    this.specsValues = response.data
                }
            },
            getFiltersSelected() {

                let filtersSelected = []
                let filtersNames = Object.keys(this.filters)
                for (let index in filtersNames) {
                    
                    filtersSelected[filtersNames[index]] = null
                }

                return filtersSelected
            },
            addTestSpecsOffers(type, offer) {

                this.error = ''
                let offerKey = this.getOfferKey(offer)
                if (this.testSpecsOffers[type] == undefined || this.testSpecsOffers[type].find(element => this.getOfferKey(element) == offerKey) == undefined) {
                    
                    if (this.hasNullValue(offer)) {

                        this.error = 'Vous devez spécifier une valeur pour chaque attribut'
                        return
                    }
                    let copiedOffer = {...offer}
                    this.$store.commit('addTestSpecsOffers', {type, copiedOffer})
                }
            },
            getOfferKey(offer) {

                let objectKey = ''
                for (let filterName in offer) {

                    objectKey = objectKey + offer[filterName] + '|'
                }

                return objectKey
            },
            getOfferPrice(offer) {

                let price = 0
                const types = ['amount', 'ratio']

                for (let i = 0; i < types.length; i++) {

                    const type = types[i]
                    for (const [key, value] of Object.entries(offer)) {
                    
                        if (value == undefined) return 'n/a'

                        price = this.getValuePrice(this.specsValues, key, value, price, type)
                        
                        if (price == undefined) return 'n/a'
                    }
                }

                return price.toFixed(2) + ' €'
            },
            getValuePrice(specsValues, key, value, price, type) {

                for (let specsValue of specsValues[key]) {
   
                    if (specsValue.value == value) {

                        if (this.filters[key].price_type == 'amount' && type === 'amount') { 
                            
                            if (specsValue.input == undefined) return undefined

                            price = price + specsValue.input
                            
                            return price
                        }
                        else if (this.filters[key].price_type == 'ratio' && type === 'ratio') {
                            
                            if (specsValue.input == undefined) return undefined

                            price = price * specsValue.input / 100

                            return price
                        }
                    }
                }

                return price
            },
            hasNullValue(offer) {

                for (let key in offer) {

                    if (offer[key] == undefined) return true
                }

                return false
            }
        },
        computed: {

            filterNames() {

                if (this.filters == undefined) return []

                return Object.keys(this.filters)
            },
            testSpecsOffers() {

                return this.$store.state.testSpecsOffers
            }
        },
        created() {

            this.$store.commit('initTestSpecsOffers', ['laptop', 'desktop', 'monitor'])

            this.$tracking.pageView('TestSpecs', {'Reseller ID': this.$store.state.resellerId})
        }
    }
</script>
<template>
    <main-layout>
        <h3>Offres par spécifications</h3>
        <tabs v-model="tabs" />
        <form v-on:submit.prevent>
            <div class="row">
                <div class="col-md-8">
                    <div class="form-group row align-items-center">
                        <label for="type-input" class="col-md-3 col-form-label font-weight-medium">Type de produit</label>
                        <div class="col-md-9">
                            <select class="form-control custom-select" id="type-input" v-model="type" @change="loadAttributes">
                                <option :value="null">Choisir...</option>
                                <option value="laptop">PC portables</option>
                                <option value="desktop">PC fixes</option>   
                                <option value="monitor">Ecrans d'ordinateur</option> 
                            </select>
                        </div>
                    </div>
                </div> 
            </div>
        </form>
        <div class="row">
            <div class="col-md-10">
                <form v-on:submit.prevent class="form-inline">
                    <div v-for="filterName in filterNames" class="col-4 input-group" :key="filterName">
                        <select class="form-control custom-select m-1" v-model="filtersSelected[filterName]">
                            <option disabled :value="null">{{$t('ATTRIBUTE_NAME.'+filterName)}}</option>
                            <option v-for="filterValue in filters[filterName].values" :key="filterValue.id" :value="filterValue.value != undefined ? filterValue.value : filterValue.id">{{filterValue.value != undefined ? filterValue.value : filterValue.id}}</option>
                        </select>
                    </div>
                </form>
            </div>
            <div v-if="filters" class="col-md-2">
                <div class="form-group">
                    <button class="btn btn-success" @click="addTestSpecsOffers(type, filtersSelected)">Ajouter</button>
                </div>
            </div>       
        </div>
        <div v-if="error" class="alert alert-danger mt-2" role="alert">
            {{error}}
        </div>
        <table v-if="testSpecsOffers[type] != undefined && testSpecsOffers[type].length > 0" class="table table-bordered table-sm mt-2">
            <thead>
                <th v-for="filterName in filterNames" :key="filterName">{{$t('ATTRIBUTE_NAME.'+filterName)}}</th>
                <th>Offre</th>
            </thead>
            <tbody>
                <tr v-for="offer in testSpecsOffers[type]" :key="getOfferKey(offer)">
                    <td v-for="val in offer" :key="val">{{val}}</td>
                    <td class="text-right"><strong>{{getOfferPrice(offer)}}</strong></td>
                </tr>
            </tbody>
        </table>
    </main-layout>
</template>
