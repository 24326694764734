import * as amplitude from "@amplitude/analytics-browser";

export default {

    install (Vue) {
    
        amplitude.init(
            process.env.VUE_APP_AMPLITUDE_API_KEY, 
            { "autocapture": false }
        )

        Vue.prototype.$tracking = {
            
            setUserId(userId) {

                userId = 'user-' + userId
                amplitude.setUserId(userId)
            },
            setUserProperty(property, value) {

                const identifyEvent = new amplitude.Identify()
                identifyEvent.set(property, value) 
                amplitude.identify(identifyEvent)
            },
            pageView(pageName, properties = {}) {

                properties['Page Name'] = pageName
                amplitude.track('Page View', properties)
            },
            track(eventName, properties = {}) {  

                amplitude.track(eventName, properties)
            }
        }
    }
}
