<script type="text/ecmascript-6">
    import MainLayout from '../layouts/Main.vue'
    import settingsService from '../services/settings'
    import StandardModal from '../components/StandardModal.vue'

    export default {
        
        components: {MainLayout, StandardModal},
        data() {

            return {

                tokenName: '',
                tokenSecret: '',
                tokens: {},
                deletedToken: null,
                isDeleteTokenLoading: false
            }
        },
        created() {

            this.loadTokens()

            this.$tracking.pageView('Settings', {'Reseller ID': this.$store.state.resellerId})
        },
        methods: {

            async loadTokens() {

                let response = await settingsService.listTokens()
                this.tokens = response.data
            },
            async createToken() {

                let response = await settingsService.createToken(this.tokenName, this.reseller.id)
                this.tokenSecret = response.data.token
                this.loadTokens()
            },
            cleanNewToken() {

                this.tokenName = ''
                this.tokenSecret = ''
            },
            askDelete(token) {

                this.deletedToken = token
            },
            cleanDeletedToken() {

                this.deletedToken = null
            },
            async deleteToken() {

                this.isDeleteTokenLoading = true
                await settingsService.deleteToken(this.deletedToken.id)
                this.cleanDeletedToken()
                this.isDeleteTokenLoading = false
                this.loadTokens() 
            },
            formatMoney(amount) {

                return (new Intl.NumberFormat('fr-FR').format(amount / 100)).replace(' ', ' ') + '\u00A0€'
            }
        },
        computed: {

            reseller() {

                return this.$store.getters.reseller
            },
            availableOutstanding() {

                if (!this.reseller) return 0

                return this.reseller.outstanding_ceil_amount_greendid + this.reseller.outstanding_ceil_amount - this.reseller.outstanding_missing_amount
            }
        }
    }
</script>
<template>
    <main-layout>
        <h3>Paramètres</h3>
        <hr class="mb-2">
        <h4>Encours</h4>
        <table v-if="reseller" class="table table-bordered w-50">
            <tr>
                <td><strong>Encours Greendid</strong></td>
                <td class="text-right">{{formatMoney(reseller.outstanding_ceil_amount_greendid)}}</td>
            </tr>
            <tr>
                <td><strong>Encours repreneur</strong></td>
                <td class="text-right">{{formatMoney(reseller.outstanding_ceil_amount)}}</td>
            </tr>
            <tr>
                <td><strong>Total dû</strong></td>
                <td class="text-right">{{formatMoney(reseller.outstanding_missing_amount)}}</td>
            </tr>
            <tr>
                <td><strong>Solde disponible</strong></td>
                <td class="text-right">
                    <span :class="{'text-success': availableOutstanding > 0, 'text-danger': availableOutstanding <= 0}">{{formatMoney(availableOutstanding)}}</span>
                </td>
            </tr>
        </table>
        <hr class="mb-2">
        <h4>Tokens d'api</h4>
        <table class="table table-bordered">
            <thead>
                <th>Nom</th>
                <th>Date de création</th>
                <th>Date de dernière utilisation</th>
                <th></th>
            </thead>
            <tbody>
                <tr v-for="token in tokens" :key="token.id">
                    <td>{{token.name}}</td>
                    <td>{{token.created_at|dateTime}}</td>
                    <td>{{token.last_used_at|dateTime}}</td>
                    <td class="text-center"><button class="btn btn-danger btn-sm" @click="askDelete(token)">Supprimer</button></td>
                </tr>
            </tbody>
        </table>
        <form class="mt-2" @submit.prevent="createToken">
            <div class="form-row">
                <div class="col-3">
                    <input type="text" class="form-control" placeholder="nom du token" v-model="tokenName" />
                </div>
                <div class="col-3">
                    <button class="btn btn-success" type="submit">Créer</button>
                </div>
            </div>
        </form>
        <hr class="mt-2 mb-2">
        <standard-modal v-if="tokenSecret != ''" :title="'Nouveau token'" @close="cleanNewToken">
            <template v-slot:body>
                <p>Votre token d'api : <br/>{{tokenSecret}}</p>
                <div role="alert" class="alert alert-danger">
                    Attention ce token ne vous sera présenté qu'une seule fois.
                    <br/>Merci de l'enregistrer dans un endroit sécurisé.
                </div>
            </template>
        </standard-modal>
         <standard-modal v-if="deletedToken" :title="'Supprimer un token'" @close="cleanDeletedToken">
            <template v-slot:body>
                <p>Voulez vous supprimez le token : {{deletedToken.name}} ?</p>
            </template>
            <template v-slot:footer>
                <button class="btn btn-danger" @click="deleteToken()">
                    <span v-if="isDeleteTokenLoading" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                    Supprimer
                </button>
            </template>
        </standard-modal>
    </main-layout>
</template>